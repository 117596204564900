import axios from 'axios';
import { tokenService } from '@/services/TokenService';
import EventBus from '../EventBus';

class HttpService {
  baseUrl = process.env.VUE_APP_API_URL;

  get(url: string, params: any = {}, options: any = {}) {
    return axios.get(`${this.baseUrl}${url}`, { params, headers: this.getHeaders() }).then(
      response => {
        return response.data;
      },
      error => this.handleErrors(error, options),
    );
  }

  getUrl(url: string, params: any = {}, options: any = {}) {
    return axios.get(`${url}`, { params, headers: this.getHeaders() }).then(
      response => {
        return response.data;
      },
      error => this.handleErrors(error, options),
    );
  }

  post(url: string, data: any, params: any = {}, options: any = {}) {
    return axios
      .post(`${this.baseUrl}${url}`, data, {
        params,
        headers: this.getHeaders(),
      })
      .then(
        response => {
          return response.data;
        },
        error => this.handleErrors(error, options),
      );
  }

  put(url: string, data: any, params: any = {}, options: any = {}) {
    return axios
      .put(`${this.baseUrl}${url}`, data, {
        params,
        headers: this.getHeaders(),
      })
      .then(
        response => {
          return response.data;
        },
        error => this.handleErrors(error, options),
      );
  }

  delete(url: string, data: any, params: any = {}, options: any = {}) {
    return axios
      .delete(`${this.baseUrl}${url}`, {
        params,
        data,
        headers: this.getHeaders(),
      })
      .then(
        response => {
          return response.data;
        },
        error => this.handleErrors(error, options),
      );
  }

  private handleErrors(error: any, options: any) {
    if (error && error.response && error.response.status === 401) {
      if (!(options && options.isAuthErrorSuppressed)) {
        tokenService.removeToken();
        document.location.href = '/';
        throw error.response;
      }
    }
    if (error && error.response && error.response.data && error.response.data.error) {
      if (!(options && options.isErrorSuppressed)) {
        EventBus.$emit('show-snackbar', error.response.data.error);
      }
    } else {
      const status = error && error.response && error.response.status ? error.response.status : 0;
      switch (status) {
        case 401:
          if (!(options && options.isAuthErrorSuppressed)) {
            EventBus.$emit('show-snackbar', 'Wystąpił błąd autoryzacji');
          }
          break;
        case 404:
          if (!(options && options.isNotFoundErrorSuppressed)) {
            EventBus.$emit('show-snackbar', 'Błąd serwera: 404 nie znaleziono');
          }
          break;
        default:
          EventBus.$emit('show-snackbar', `Wystąpił błąd serwera (kod: ${status})`);
      }
    }
    throw error.response ? error.response : { code: 0, data: {} };
  }

  private getHeaders() {
    const headers: any = {
      'Content-Type': 'application/json',
    };

    const token = tokenService.getToken();

    if (token) {
      headers['Authorization'] = token;
    }

    return headers;
  }
}

export const httpService = new HttpService();
