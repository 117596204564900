class LocaleService {
  setLocale(locale: string) {
    localStorage.setItem('locale', locale);
  }

  getLocale(): string {
    let language = localStorage.getItem('locale');

    if (language) {
      return language;
    }

    language = window.navigator.language;

    if ((language || '').indexOf('pl') !== -1) {
      return 'pl';
    }

    return 'en';
  }
}

export const localeService = new LocaleService();
