<template>
  <div id="app" @click="documentClick()">
    <router-view />
    <div class="snackbar-container">
      <div
        class="snackbar"
        :class="{
          info: snackbar.type === 'info',
          success: snackbar.type === 'success',
        }"
        v-for="(snackbar, index) in snackbars"
        :key="`snackbar-${index}`"
        @click="shiftSnackbar()"
      >
        {{ snackbar.data }}
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '@/EventBus';

export default {
  methods: {
    documentClick: function() {
      EventBus.$emit('document-clicked');
    },
    shiftSnackbar: function() {
      this.snackbars.shift();
    },
  },
  data: function() {
    return {
      snackbars: [],
    };
  },
  mounted: function() {
    const self = this;

    EventBus.$on('show-snackbar', function(text) {
      const found = self.snackbars.find(function(item) {
        return item.data === text;
      });
      if (!found) {
        self.snackbars.push({ type: 'error', data: text });
        setTimeout(function() {
          self.snackbars.shift();
        }, 5000);
      }
    });

    EventBus.$on('show-info', function(text) {
      self.snackbars.push({ type: 'info', data: text });
      setTimeout(function() {
        self.snackbars.shift();
      }, 5000);
    });

    EventBus.$on('show-success', function(text) {
      self.snackbars.push({ type: 'success', data: text });
      setTimeout(function() {
        self.snackbars.shift();
      }, 5000);
    });
  },
};
</script>

<style lang="scss">
@import 'styles/global.scss';
@import './styles/password-font/asterisk.scss';

select {
  padding: 10px;
  width: 150px;
  margin: 0 5px 0 0;
  border: 1px solid #ccc;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -webkit-appearance: button;
  -moz-appearance: button;
  -webkit-user-select: none;
  -moz-user-select: none;
  -webkit-padding-end: 20px;
  -moz-padding-end: 20px;
  -webkit-padding-start: 2px;
  -moz-padding-start: 2px;
  appearance: none;
  position: relative;
  background: #ffffff url('assets/icons/selectbox-arrow.png') no-repeat right center;
}
</style>
