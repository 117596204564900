import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Login from '../views/Login.vue';
import { tokenService } from '@/services/TokenService';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: Login,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/applications',
    name: 'Applications',
    component: () => import(/* webpackChunkName: "Applications" */ '../views/Applications.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/applications/:type',
    name: 'ApplicationsType',
    component: () => import(/* webpackChunkName: "Applications" */ '../views/Applications.vue'),
    meta: { requiresAuth: true },
  },

  {
    path: '/proposals',
    name: 'Proposals',
    component: () => import(/* webpackChunkName: "Proposals" */ '../views/Proposals.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/proposals/:type',
    name: 'ProposalsType',
    component: () => import(/* webpackChunkName: "Proposals" */ '../views/Proposals.vue'),
    meta: { requiresAuth: true },
  },

  {
    path: '/documents',
    name: 'Documents',
    component: () => import(/* webpackChunkName: "Complaints" */ '../views/Documents.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "notFound" */ '../views/NotFound.vue'),
    meta: {},
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!tokenService.getToken()) {
      next({
        path: '/',
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
